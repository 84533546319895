import React from 'react';

const pro_xp = [
  {
    name: "Advisor",
    date: "2018-Present",
    org: "Red Crescent student Association"
  },
  {
    name: "General Secretary of Sport in Nature Association",
    date: "2017-Present",
    org: "ISFAF (Iran Sport For All Federation)"
  },
  {
    name: "Marketing and Industry Communication Manager",
    date: "2017-Present",
    org: "Kharazmi University (Physical Education & Sport Science Faculty)"
  },
  {
    name: "Secretary of Sport Management Academic Association",
    date: "2016-2017",
    org: "Kharazmi University (Physical Education & Sport Science Faculty)"
  },
  {
    name: "Secretary of Volleyball Association",
    date: "2008-2009",
    org: "Kharazmi University"
  },
  {
    name: "Volleyball Coach",
    date: "2014",
    org: "Bonyad Maskan (Sanandaj)"
  },
  {
    name: "Basketball Head Coach",
    date: "2017-Present",
    org: "Kharazmi University"
  },
  {
    name: "Volunteers Committee manager of National Conference of Sport in Childhood",
    date: "2017",
    org: "Kharazmi University"
  },
  {
    name: "Vice executive manager of National Conference of Sport in Childhood",
    date: "2018",
    org: "Kharazmi University"
  },
  {
    name: "Executive manager of National Entrance Exam for Undergraduate Physical Education",
    date: "2017",
    org: "Kharazmi University"
  },
  {
    name: "Referee of National Entrance Exam for Physical Education",
    date: "2016",
    org: "Kharazmi University"
  },
  {
    name: "Students Agent in University Disciplinary Committee",
    date: "2016-2017",
    org: "Kharazmi University"
  },
  {
    name: "Workshop Director and Speaker",
    date: "2015",
    org: "First National Conference of Sport for All"
  },
  {
    name: "Sport Facility Administrator of 11th Iranian University Students Sports Olympiad",
    date: "2012",
    org: "Urmia University"
  },
  {
    name: "Volunteer in 8th International Conference on Physical Education & sport Science",
    date: "2014",
    org: "The Scientific Studies and Research Centre (SSRC), Tehran, Iran"
  },
  {
    name: "Volunteer in 9th International Conference on Physical Education & sport Science",
    date: "2015",
    org: "The Scientific Studies and Research Centre (SSRC), Tehran, Iran"
  },
  {
    name: "Participating in International Teaching Week (Erasmus+)",
    date: "March 2019",
    org: "Palacký University, Olomouc, Czechia"
  },
  {
    name: "Referee in Scientific Research Semiannual Journal of",
    date: "2018- present",
    org: "“Research in Sport Management and Motor Behavior”"
  }
];

class ProfessionalExperience extends React.Component {
  render() {
    return (
      <section id="professional-experience">
        <div className="wrapper">
          <h2>Professional Experience</h2>
          <div className="grid-wrapper">
            {pro_xp.map((element) => (
              <div className="item-inner-border">
                <div data-aos="fade">
                  <p><b>{element.name}</b></p>
                  <p>{element.org}</p>
                  <p>{element.date}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default ProfessionalExperience;
