import React from 'react';

class Cover extends React.Component {
  render() {
    return(
      <section id="cover-section" data-aos="fade" data-aos-delay="1000">
        <div id="cover-initials" data-aos="fade" data-aos-delay="2000">
          <h1>Chalak Majidi</h1>
          <div className="inner">
            <h2><b>Assistant Professor</b></h2>
            <p>Faculty of Physical Education &amp; Sport Science Kharazmi
              University, Tehran, Iran</p>
            <p><a href="tel:+98918-870-5032">(+98) 918-870-5032</a></p>
            <p><a href="mailto:chalakmajidi@khu.ac.ir">
              chalakmajidi@khu.ac.ir</a></p>
          </div>
          <div className="inner">
            <h2><b>Education</b></h2>
            <div className="education">
              <p><b>Ph.D in Sport Management</b></p>
              <p>Kharazmi University (2017)</p>
            </div>
            <div className="education">
              <p><b>M.A. in Sport Management</b></p>
              <p>Urmia University (2013)</p>
            </div>
            <div className="education">
              <p><b>B.Sc in Physical Education and Sport Science</b></p>
              <p>Kharazmi University (2011)</p>
            </div>
          </div>
        </div>
      </section>

    );
  }
}

export default Cover;
