import React from 'react';

const teaching_xp = [
  {
    name: "Kharazmi University",
    department: "Sport Management Department",
    date: "2015-Present",
    courses: [
      "Fundamental of Sport management",
      "Organizing Sport Events",
      "Sport Facility Management",
      "Ethics and Law in Sport",
      "Sociology of Sport"
    ]
  },
  {
    name: "Kharazmi University",
    department: "Sports Coaching Department",
    date: "2014-Present",
    courses: [
      "Basketball 101 & 102",
      "Volleyball 101 & 102"
    ]
  },
  {
    name: "Islamic Azad University of Karaj",
    department: "",
    date: "2017",
    courses: [
      "Volleyball 101 & 102"
    ]
  },
  {
    name: "Kurdestan University",
    department: "",
    date: "2013-2014",
    courses: [
      "General Physical Education 101 & 102"
    ]
  },
  {
    name: "Urmia University",
    department: "",
    date: "2011-2012",
    courses: [
      "General Physical Education 101 & 102"
    ]
  },
  {
    name: "Islamic Azad University of Varamin",
    department: "",
    date: "2015",
    courses: [
      "Fundamental of Sport management",
      "Organizing Sport Events"
    ]
  }
];

class TeachingExperience extends React.Component {
  render() {
    return (
      <section id="teaching-experience">
        <div className="wrapper">
          <h2>Teaching Experience</h2>
          <div className="grid-wrapper">
            {teaching_xp.map((element) => (
              <div className="item-inner-border">
                <div data-aos="fade">
                  <p><b>{element.name}</b></p>
                  {element.department.length !== 0 &&
                    (<p>{element.department}</p>)}
                  <p>{element.date}</p>
                  {element.courses.map((course) => (
                    <p>{course}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default TeachingExperience;
