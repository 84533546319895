import React from 'react';

const skills = [
  {
    cat: "IT & Computer Skills",
    items: [
      "Operating Systems Packages",
      "International Computer Driving Licence (ICDL)",
      "Softwares including SPSS, LISREL, PLS and EndNote"
    ]
  },
  {
    cat: "Other Skills",
    items: [
      "Communication and Public Speaking",
      "Knowledge of research methodologies (Qualitative & Quantitative)",
      "Data and information collection",
      "Writing and presenting reports"
    ]
  }
];

class Skills extends React.Component {
  render() {
    return(
      <section id="skills">
        <div className="wrapper">
          <h2>Skills</h2>
          <div className="grid-wrapper">
            {skills.map((element) => (
              <div className="item-inner-border">
                <div data-aos="fade">
                  <h3>{element.cat}</h3>
                  {element.items.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
