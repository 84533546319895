import React from 'react';

const xp = [
  {
    name: "Sport Management Department Lecturer",
    date: "2015 - Present"
  },
  {
    name: "Sports Coaching Department",
    date: "2011 - Present",
  },
  {
    name: "General Secretary of Sport in Nature Association",
    date: "2017 - 2018",
  },
  {
    name: "Marketing and Industry Communication Manager",
    date: "2017 - Present"
  }
];

class Experience extends React.Component {
  render() {
    return (
      <section id="experience">
        <div className="wrapper">
          <h2>Experience</h2>
          <div className="grid-wrapper">
            {xp.map((element) => (
              <div className="item-inner-border">
                <div data-aos="fade">
                  <p><b>{element.name}</b></p>
                  <p>{element.date}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Experience;
