import React from 'react';

const paper_published = [
  "The Factors Related to National Production and Iranian Labor and Capital Support in Sport (2015), S.M, Kashesf; B,Ahadi, & Ch. Majidi,. SPORT MANAGEMENT (HARAKAT), 7(3), 351-366.",
  "Strategy of adventure sports development in Sanandaj city by SWOT analysis (2016), Ch. Majidi, & M. Moharramzadeh,. Research in Sport Management & Motor Behavior, : 6(12), 44-27.",
  "The Factors Related to Beautification and joyfulness of university sport places on dormitory student’s tendency toward physical activity (2016), Ch. Majidi, S.M. Kashef, B. Ahadi, & G. Rasoolazar,. Applied Research of Sport Management, 4(3).",
  "The study of occupational and organizational stresses in technical staff of 16th Asian men’s volleyball championship (2015), B. Ahadi, M. Moharamzadeh, Ch. Majidi, G. Rasoolazar,. Applied Research of Sport Management, 4(2), 11-22."
];

const paper_accepted = [
  "Adventure sports development pattern (2017), Ch. Majidi, M, Saffari, Alireza Elahi, Mohammad Khabiri. Research in Sport Management & Motor Behavior, In Press."
];

const paper_ongoing = [
  "Model of participation in adventure sports activities Dr. Chalak Majidi, Dr. Marjan Saffari, Dr. Alireza Elahi",
  "Investigating history of adventure sports through cinematic media Dr. Chalak Majidi",
  "Feasibility of sport tourism with focus on mountain biking (case study: Abidar Mountain, Sanandaj city) Dr. Chalak Majidi, Sattar Karimi, Someye Feizi"
];

const research_projects = [
  "Development solutions of national production and supporting Iranian labor and capital in sport (2012), S.M, Kashesf; Ch. Majidi & B, Ahadi, Urmia University.",
  "Strategic plan of I.R.Iran Tennis Federation (Ongoing), Dr. Alireza Elahi, Dr. Chalak Majidi, Nasim Keshmiri",
  "Comparative Study of structure and processes of selected worldwide university (Ongoing), Dr. Alireza Elahi, Dr. Chalak Majidi",
  "Designing plan (Strategies, Approach and Operations) Iran`s Sport system according to Resistive Economic Policies (Ongoing), Dr. Alireza Elahi, Dr. Hossein Akbari Yazdi, Dr. Chalak Majidi, Reza Khorshidi, Nasim Keshmiri"
];

const conference_papers = [
  "Adventure sports development in Sanandaj city (2015), Chalak Majidi, Dr. Marjan Safffari, 1st National Conference on Sport for All.",
  "The Factors Related to National Production and Iranian Labor and Capital Support in Sport (2015), Chalak Majidi, Dr. S.M. Kashef & Behzad Ahadi, Comprehensive and International Conferences of Resistive Economics.",
  "The study of occupational stresses in Handball coaches in cities of Sanandaj, Urmia & Hamedan (2012), Behzad Ahadi, Dr. Mehrdad Moharamzadeh, Chalak Majidi, & Golalesh Rasoolazar. 2nd National Conference on Handball and Science",
  "Relation investigating the entrepreneurship with job stress in employees of Ardebil`s general directorate of youth and sport (2013), Behzad ahadi, Mirhassan seyyed Ameri, Elham Montazer alaih, Chalak Majidi, National Conference on Entrepreneurship University, Science Based Industry."
];

const books = [
  "Group Dynamics in Exercise and Sport Psychology (2007), Dr. Saeed Sadeghi Boroujerdi, Dr. Bahram Yousefi, Chalak Majidi, Nasim Heidari. Hatmi Press (2015).",
  "The Sports Management Toolkit (2011), Dr. Mehrdad Moharramzadeh, Yaser Samkhaniani, Chalak Majidi, Academic Center for Education, Culture and Research Press (2013)"
];

const compilations = [
  "Adventure Sport Activities (Ongoing) - Dr. Chalak Majidi",
  "Practical Fundamentals and Systems Quality Management in Sports - Dr. Hossein Akbari Yazdi, Dr, Chalak Majidi",
  "Practical Drills for Teaching Basketball - Dr. Chalak Majidi"
];

class Research extends React.Component {
  render() {
    return(
      <section id="research">
        <div className="wrapper">
          <h2><b>Papers</b></h2>
          <h3>Published</h3>
          <ul>
            {paper_published.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
          <h3>Accepted</h3>
          <ul>
            {paper_accepted.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
          <h3>Ongoing</h3>
          <ul>
            {paper_ongoing.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
          <hr />
          <h2><b>Research Projects</b></h2>
          <ul>
            {research_projects.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
          <hr />
          <h2><b>Conference Papers</b></h2>
          <ul>
            {conference_papers.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
          <hr />
          <h2><b>Books</b></h2>
          <ul>
            {books.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
          <hr />
          <h2><b>Compilations</b></h2>
          <ul>
            {compilations.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
        </div>
      </section>
    );
  }
}

export default Research;
