import React from 'react';

const certificates = [
  {
    id: "conditioning-coaching",
    name: "Conditioning Coaching",
    grade: "Excellence",
    org: "National Olympic and Paralympic Academy of Iran"
  },
  {
    id: "basketball",
    name: "Basketball Coaching",
    grade: "Grade 2",
    org: "I.R.Iran Basketball Federation"
  },
  {
    id: "track-and-field",
    name: "Track and Field" ,
    grade: "Grade 2",
    org: "Athletics Federation of I.R.Iran"
  },
  {
    id: "swimming",
    name: "Swimming Coaching",
    grade: "Grade 3",
    org: "Swimming Federation Of Iran"
  },
  {
    id: "volleyball",
    name: "Volleyball Coaching",
    grade: "Grade 3",
    org: "I.R.Iran Volleyball Federation"
  },
  {
    id: "fitness",
    name: "Fitness Coaching",
    grade: "Grade 3",
    org: "Iran Sport For All Federation"
  },
  {
    id: "bodybuilding",
    name: "Bodybuilding and Powerlifting",
    grade: "Grade 3",
    org: "I.R.Iran Federation of Bodybuilding and Fitness"
  },
  {
    id: "trx",
    name: "TRX",
    grade: "",
    org: "Iran Sport For All Federation"
  }
];

class Certificates extends React.Component {
  render() {
    return(
      <section id="certificates">
        <div className="wrapper">
          <h2>Certificates</h2>
          <div className="grid-wrapper">
            {certificates.map((element) => (
              <div className="item-back-image" id={element.id}>
                <div data-aos="fade" data-aos-delay="500">
                  <p><b>{element.name}</b></p>
                  <p>{element.grade}</p>
                  <p>{element.org}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Certificates;
