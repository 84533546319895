import React from 'react';

class References extends React.Component {
  render() {
    return (
      <section id="references">
        <div className="references-data" data-aos="fade">
          <h2><b>My Social Account</b></h2>
          <div id="socials">
            <a href="https://khu.ac.ir/cv/2884/%DA%86%D8%A7%D9%84%D8%A7%DA%A9%20%D9%85%D8%AC%DB%8C%D8%AF%DB%8C#"><i class="fas fa-university"></i></a>
            <a href="https://twitter.com/ChalakMajidi"><i class="fab fa-twitter"></i></a>
            <a href="https://www.linkedin.com/in/chalak-majidi-77a01055/"><i class="fab fa-linkedin-in"></i></a>
            <a href="https://www.researchgate.net/profile/Chalak_Majidi"><i class="fab fa-researchgate"></i></a>
            <a href="https://t.me/ChalakMajidi"><i class="fab fa-telegram"></i></a>
          </div>
        </div>
      </section>
    );
  }
}

export default References;
