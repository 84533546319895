import React from 'react';

const honors_scientific = [
  "Best Researcher of the Kharazmi University, 2016-2017"
];

const honors_sports = [
  "2nd Place Basketball Championship, 11th Sport- Cultural University Students Olympiad, March 2012, (Urmia University Basketball Team)",
  "1st Place Volleyball Championship, State’s Employees of Alborz Province, 2018 (Kharazmi University Volleyball Team)",
  "3rd Place in the International Basketball Tournament, 55th Anniversary of Ataturk University, April 2012, (I. R. Iran Universities Students Team)",
  "1st Place Basketball National Championship, Zone 2 Universities, 2010 (Kharazmi University Basketball Team)",
  "2nd Place Swimming National Championship, Zone 2 Universities, 2010 (Kharazmi University Swimming Team)",
  "1st Place Basketball National Championship, Zone 2 Universities, 2009 (Kharazmi University Basketball Team)",
  "1st Place Volleyball National Championship, Zone 2 Universities, 2009 (Kharazmi University Volleyball Team)"
];

class Honors extends React.Component {
  render() {
    return (
      <section id="honors">
        <div className="wrapper">
          <h2><b>Honors</b></h2>
          <h3>Scientific</h3>
          <ul>
            {honors_scientific.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
          <h3>Sports</h3>
          <ul>
            {honors_sports.map((element) => (
              <li key={"key_" + element}>{element}</li>
            ))}
          </ul>
        </div>
      </section>
    );
  }
}

export default Honors;
