import React from 'react';
import './assets/scss/app.scss';
import '../node_modules/aos/dist/aos.css';
import './assets/css/fontawesome.css';
import './assets/css/brands.css';
import './assets/css/solid.css';
import AOS from 'aos';
import Cover from './components/cover.jsx';
import Experience from './components/experience.jsx';
import Skills from './components/skills.jsx';
import Certificates from './components/certificates.jsx';
import Research from './components/research.jsx';
import TeachingExperience from './components/teaching_experience.jsx';
import ProfessionalExperience from './components/professional_experience.jsx';
import Honors from './components/honors.jsx';
import References from './components/references.jsx';

class App extends React.Component {
  componentDidMount() {
    AOS.init({
      offset: 120, // offset (in px) from the original trigger point
      delay: 200, // values from 0 to 3000, with step 50ms
      duration: 1500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
    });
  }
  render() {
    return (
      <div>
        <Cover />
        <Experience />
        <Skills />
        <Certificates />
        <Research />
        <TeachingExperience />
        <ProfessionalExperience />
        <Honors />
        <References />
      </div>
    );
  }
}

export default App;
